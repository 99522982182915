import React from "react";
import { Row, Col } from "react-flexbox-grid";
// SCSS
import "./hero.scss";
//Components
import Button from "../ui-components/button/button";
import Editor from "./editor/editor";

const hero = () => (
  <div className="hero" id="hero">
    <div className="wrapper">
      <Row>
        <Col md={12} lg={6}>
          <div className="hero-info">
            <h1 className="weight800 font60">Hi, </h1>
            <h1 className="weight800 font60">I am Vasil.</h1>
            <p className="font16">
              Creative Front-End Developer, proficient in HTML5, CSS3,
              JavaScript, React, Redux, Node.js, Express.js, Git, RESTful APIs,
              Photoshop and more.
            </p>
            <div className="hero-buttons">
              <Button label="Send me a message" target={"contact"} />
              <Button
                regular
                label="Download my resume"
                target={"/vasil_chilingirov_resume_la.pdf"}
                download
              />
            </div>
          </div>
        </Col>
        <Col md={12} lg={6}>
          <Editor />
        </Col>
      </Row>
    </div>
  </div>
);

export default hero;
