import React from "react";
// SCSS
import "./teamInfo.scss";

const about = () => (
  <div className="team__info flex-center">
    <div>
      <p className="font14 weight500">
        Hi there, my name is Vasil. I am a self-taught Front End Developer born
        in Bulgaria and currently based in Los Angeles, CA. I have a passion for
        creating fast and beautiful web applications using the latest
        technologies. My skills include but are not limited to HTML5, CSS3,
        JavaScript, React, Redux, Node.js, Express.js, Git, RESTful APIs,
        Photoshop, and more.
      </p>
      <p className="font14 weight500">
        2013 was the year that changed my life. While I was at college, trying
        to become an accountant, I took my first web development class that
        taught me how to build websites for small businesses. At this moment, I
        didn't realize that this class would determine my future and push my
        life intо a different direction, but it did. One year later, I graduated
        from the university as an accountant - and my first job was as a Web
        Submitter/Website developer at one of the biggest internet marketing
        agencies in Bulgaria. Two years after that, I was already a freelance
        developer living in California.
      </p>
      <p className="font14 weight500">
        Currently, I am working on a project called Jobonado - a progressive web
        application for jobs built using a blockchain.
      </p>
    </div>
  </div>
);

export default about;
