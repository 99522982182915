import React from "react";
import Typewriter from "typewriter-effect";
import { Row, Col } from "react-flexbox-grid";
// SCSS
import "./editor.scss";

const editor = () => (
  <div className="hero-editor">
    <div className="editor-topbar">
      <div className="editor-action-buttons" />
    </div>
    <Row className="editor-content">
      <Col xs={1} className="editor-first-column">
        <span className="editor-column-element">1</span>
        <span className="editor-column-element">2</span>
        <span className="editor-column-element">3</span>
        <span className="editor-column-element">4</span>
        <span className="editor-column-element">5</span>
        <span className="editor-column-element">6</span>
        <span className="editor-column-element">7</span>
        <span className="editor-column-element">8</span>
        <span className="editor-column-element">9</span>
        <span className="editor-column-element">10</span>
        <span className="editor-column-element">11</span>
        <span className="editor-column-element">12</span>
        <span className="editor-column-element">13</span>
        <span className="editor-column-element">14</span>
      </Col>
      <Col xs={11}>
        <span className="editor-comment">
          //Please, follow the instructions bellow
        </span>
        <Typewriter
          options={{
            delay: 30,
          }}
          onInit={(typewriter) => {
            typewriter
              .pauseFor(1000)
              .typeString("<br>")
              .typeString(
                "<span class='editor-keyword'>function</span> <span class='editor-functions'>hireNewEmployee</span> <span class='editor-brackets'>(</span><span class='editor-variables'>name</span><span class='editor-brackets'>) {</span><br>"
              )
              .pauseFor(300)
              .typeString(
                "<span class='editor-keyword' style='margin-left:50px'>return</span> <span class='editor-strings'>`Congratulations ${<span class='editor-variables'>name</span>}, you are hired!`</span><br>"
              )
              .typeString("<span class='editor-brackets'>}</span><br>")
              .typeString("<br>")
              .pauseFor(300)
              .typeString(
                '<span class="editor-keyword">const</span> <span class="editor-constants">newEmployee</span> <span class="editor-brackets">=</span> <span class="editor-functions">hireNewEmployee</span><span class="editor-brackets">(</span><span class="editor-strings">"Vasil"</span><span class="editor-brackets">)</span><br>'
              )
              .pauseFor(300)
              .typeString("<br>")
              .typeString(
                "<span class='editor-special'>console</span><span class='editor-brackets'>.</span><span class='editor-functions'>log</span><span class='editor-brackets'>(</span><span class='editor-constants'>newEmployee</span><span class='editor-brackets'>);</span>"
              )
              .pauseFor(300)
              .start();
          }}
        />
      </Col>
    </Row>
  </div>
);

function hireNewEmployee(name) {
  return `Congratulations ${name}, you are hired!`;
}

const newEmployee = hireNewEmployee("Vasil");
console.log(newEmployee);

export default editor;
