import React from "react";
import { Row, Col } from "react-flexbox-grid";
import Masonry from "react-masonry-css";
//Scss
import "./portfolio.scss";
//Assets
import Arrow from "../../assets/portfolio/arrow.svg";
import Waterfall from "../../assets/portfolio/project01/waterfall.jpg";
import Meditation from "../../assets/portfolio/project02/meditation.jpg";
import Ai from "../../assets/portfolio/project03/ai.jpg";
import Budget from "../../assets/portfolio/project04/budget.jpg";
import Idysign from "../../assets/portfolio/project05/idysign.jpg";
import Memory from "../../assets/portfolio/project06/memory.png";
import Indigo from "../../assets/portfolio/project07/indigo.png";
import Imappper from "../../assets/portfolio/project08/imappper.jpg";
import Lions from "../../assets/portfolio/project09/lions.jpg";
import Animals from "../../assets/portfolio/project10/animals.jpg";
//Components
import Title from "../ui-components/title/title";
import ProjectBox from "../ui-components/projectBox/projectBox";

class Portfolio extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // PORTFOLIO PROJECTS
      projects: [
        {
          id: "1",
          preview: Waterfall,
          link: "https://chilingirov.github.io/waterfalls-website/",
          title: "Waterfall - website template",
          tag: "websites",
          stack: "HTML, CSS, JavaScript and Bootstrap",
        },
        {
          id: "2",
          preview: Meditation,
          link: "https://chilingirov.github.io/breathing-timer/",
          title: "Breathing Meditation Timer",
          tag: "web apps",
          stack: "React, React Router and Material UI",
        },
        {
          id: "3",
          preview: Ai,
          link: "https://chilingirov.github.io/ai-brainiac-frontend/",
          title: "Smart Brain",
          tag: "web apps",
          stack: "React, React Router and Clarify AI library",
        },
        {
          id: "4",
          preview: Budget,
          link: "https://chilingirov.github.io/budget-app/",
          title: "Budget vanila js app",
          tag: "web apps",
          stack: "HTML, CSS and Vanilla JS",
        },
        {
          id: "5",
          preview: Idysign,
          link: "https://idysign.com",
          title: "IdySign - photography website",
          tag: "websites",
          stack: "WordPress and Photoshop",
        },
        {
          id: "6",
          preview: Memory,
          link: "https://chilingirov.github.io/memory-game/",
          title: "Memory game",
          tag: "games",
          stack: "HTML, CSS, Vanilla JS and Photoshop",
        },
        {
          id: "7",
          preview: Indigo,
          link: "https://chilingirov.github.io/indigo/",
          title: "Indigo Website Template",
          tag: "websites",
          stack: "HTML, CSS, Vanilla JS and Photoshop",
        },
        {
          id: "8",
          preview: Imappper,
          link: "https://chilingirov.github.io/Imappper/",
          title: "Imappper - Google Maps Web App",
          tag: "web apps",
          stack: "HTML, CSS, Knockout JS, Photoshop and Google Maps",
        },

        {
          id: "9",
          preview: Lions,
          link: "https://www.deviantart.com/vasil988/art/Fantasy-World-photoshop-photo-manipulation-652599806",
          title: "Fantasy World",
          tag: "photoshop manipulations",
          stack: "Photoshop",
        },
        {
          id: "10",
          preview: Animals,
          link: "https://www.deviantart.com/vasil988/art/Los-Angeles-Animal-Kingdom-photo-manipulation-652598854",
          title: "Los Angeles Animal Kingdom",
          tag: "photoshop manipulations",
          stack: "Photoshop",
        },
      ],
      // PORTFOLIO GALLERY WILL LOAD THIS AFTER FUNCTION "filterGallery" FINISH FILTERING
      filterResult: null,
      pickedFilter: "all",
      filterMenuActive: false,
      pickedFilterDropdown: "NEWEST",
    };
  }

  // FIRST LOAD
  componentDidMount() {
    this.filterGallery("all");
  }

  //FILTER PORTFOLIO FUNCTION
  filterGallery = (target) => {
    let projectsArr = [...this.state.projects];
    let result;

    if (target !== "all") {
      result = projectsArr.filter((project) => project.tag === target);
    } else {
      result = projectsArr;
    }

    this.setState({
      filterResult: result,
      pickedFilter: target,
      pickedFilterDropdown: "NEWEST",
    });
  };

  // FILTER DROP DOWN HOVER MENU FUNCTION
  filterMenuHover = (event) => {
    if (event) {
      this.setState({ filterMenuActive: true });
    } else {
      this.setState({ filterMenuActive: false });
    }
  };

  // FILTER DROP DOWN HANDLER
  filterDropDownHandler = (filter) => {
    this.setState({ pickedFilterDropdown: filter, filterMenuActive: false });

    let projectsArr = [...this.state.filterResult];
    let result;

    if (filter === "NEWEST") {
      result = projectsArr.sort((a, b) => (a.id > b.id ? 1 : -1));
    } else if (filter === "OLDEST") {
      result = projectsArr.sort((a, b) => (a.id > b.id ? 1 : -1)).reverse();
    }

    this.setState({ filterResult: result });
  };

  // RENDER
  render() {
    // PORTFOLIO GALLERY RENDER
    let projectsRender = null;
    if (this.state.filterResult) {
      projectsRender = this.state.filterResult.map((project) => (
        <ProjectBox
          preview={project.preview}
          key={project.id}
          title={project.title}
          tag={project.tag}
          link={project.link}
          stack={project.stack}
        />
      ));
    }
    // PORTFOLIO GALLERY BREAKPOINTS
    const portfolioBreakpoints = {
      default: 3,
      1100: 3,
      700: 2,
      500: 1,
    };
    // PORTFOLIO FILTER DROPDOWN MENY RENDER
    let filterDroppDown = null;
    if (this.state.filterMenuActive) {
      filterDroppDown = (
        <div className="portfolio__filter-menu shadow">
          <p
            className="font12"
            onClick={() => this.filterDropDownHandler("NEWEST")}
          >
            NEWEST
          </p>
          <p
            className="font12"
            onClick={() => this.filterDropDownHandler("OLDEST")}
          >
            OLDEST
          </p>
        </div>
      );
    }

    return (
      <div id="portfolio">
        <div className="wrapper">
          <Title title="Check my portfolio." />
          <Row>
            <Col xs={12} sm={12} md={8} lg={9}>
              <div className="portfolio__nav">
                <ul>
                  <li
                    className={
                      this.state.pickedFilter === "all"
                        ? "portfolio__nav-active font12"
                        : "font12"
                    }
                    onClick={() => this.filterGallery("all")}
                  >
                    ALL
                  </li>
                  <li
                    className={
                      this.state.pickedFilter === "websites"
                        ? "portfolio__nav-active font12"
                        : "font12"
                    }
                    onClick={() => this.filterGallery("websites")}
                  >
                    WEBSITES
                  </li>
                  <li
                    className={
                      this.state.pickedFilter === "web apps"
                        ? "portfolio__nav-active font12"
                        : "font12"
                    }
                    onClick={() => this.filterGallery("web apps")}
                  >
                    WEB APPS
                  </li>
                  <li
                    className={
                      this.state.pickedFilter === "photoshop manipulations"
                        ? "portfolio__nav-active font12"
                        : "font12"
                    }
                    onClick={() =>
                      this.filterGallery("photoshop manipulations")
                    }
                  >
                    PHOTOSHOP MANIPULATIONS
                  </li>
                  <li
                    className={
                      this.state.pickedFilter === "games"
                        ? "portfolio__nav-active font12"
                        : "font12"
                    }
                    onClick={() => this.filterGallery("games")}
                  >
                    GAMES
                  </li>
                </ul>
              </div>
            </Col>
            <Col xs={12} sm={12} md={4} lg={3}>
              <div
                className="portfolio__filter"
                onMouseEnter={() => this.filterMenuHover(true)}
                onMouseLeave={() => this.filterMenuHover(false)}
              >
                <p className="font12">
                  {this.state.pickedFilterDropdown} FIRST
                </p>
                <img src={Arrow} alt="arrow" />
                {filterDroppDown}
              </div>
            </Col>
          </Row>
          <Masonry
            breakpointCols={portfolioBreakpoints}
            className="my-masonry-grid"
            columnClassName="mint__gallery"
          >
            {projectsRender}
          </Masonry>
        </div>
      </div>
    );
  }
}

export default Portfolio;
