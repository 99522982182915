import React from "react";
import { Row, Col } from "react-flexbox-grid";
import "./contactSocial.scss";

import GithubIcon from "../../../assets/contact/github.svg";
import LinkedinIcon from "../../../assets/contact/linkedin.svg";

const contactSocial = () => (
  <Row>
    <Col xs={12}>
      <Row center="xs">
        <Col xs={3} sm={1} className="contact__social">
          <a
            href="https://github.com/chilingirov"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={GithubIcon} alt="facebook" />
          </a>
        </Col>
        <Col xs={3} sm={1} className="contact__social">
          <a
            href="https://www.linkedin.com/in/vasil-chilingirov/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={LinkedinIcon} alt="Twitter" />
          </a>
        </Col>
      </Row>
    </Col>
  </Row>
);

export default contactSocial;
