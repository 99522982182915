import React from "react";
import { Link } from "react-scroll";
// SCSS
import "./button.scss";

const hero = ({ target, label, regular, ...rest }) => (
  <div className="button">
    {regular ? (
      <a className="button-link" href={target} {...rest}>
        {label}
      </a>
    ) : (
      <Link
        className="button-link"
        to={target}
        spy={true}
        smooth={true}
        offset={-70}
        duration={500}
        {...rest}
      >
        {label}
      </Link>
    )}
  </div>
);

export default hero;
