import React from "react";
// Scss
import "./projectBox.scss";

const projectBox = (props) => (
  <div className="portfolio__box">
    <a href={props.link} target="_blank" rel="noopener noreferrer">
      <img src={props.preview} alt="project" />
      <div className="portfolio__hover-info flex-center">
        <div className="text-center">
          <p className="font22 weight800">{props.title}</p>
          <span className="font12 stack">{`Built using - ${props.stack}`}</span>
          <p className="font12 weight500">{props.tag}</p>
        </div>
      </div>
    </a>
  </div>
);

export default projectBox;
