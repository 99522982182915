import React from "react";
import { Row, Col } from "react-flexbox-grid";
import "./about.scss";
// Components
import TeamBox from "./teamBox";
import TeamInfo from "./teamInfo";
import Title from "../ui-components/title/title";
// Assets
import Vasil from "../../assets/about/vasil.jpg";

const about = () => (
  <div id="about">
    <div className="wrapper">
      <Title title="A little about me." />
      <Row>
        <Col md={12} lg={4}>
          <TeamBox
            avatar={Vasil}
            name="Vasil Chilingirov"
            job="React Developer"
          />
        </Col>
        <Col md={12} lg={8}>
          <TeamInfo />
        </Col>
      </Row>
    </div>
  </div>
);

export default about;
